import * as React from "react";
import { DesktopNavButton } from "../components/Navigation/NavComponents";

const heroImage = require("../assets/img/homepage-hero.jpg");
const browseImage = require("../assets/img/browse.png");
const introImage = require("../assets/img/intro.png");
const aboutImage = require("../assets/img/about.png");
const learnImage = require("../assets/img/learn-more.png");

const Home = () => {
  return (
    <div className="wrapper">
      {/* <!-- Hero --> */}
      <section className="mx-auto">
        <img src={heroImage} alt="Professor Lupe Lex in his study chamber" />
      </section>
      <main className="mt-12 space-y-20 lg:space-y-32 lg:mt-16">
        {/* <!-- Intro --> */}
        <div className="bg-left-top bg-no-repeat bg-contain bg-blood-bg">
          <section className="max-w-6xl mx-auto">
            <h1 className="text-2xl font-black text-center text-gray-100 lg:text-6xl ">
              Welcome to Horror Lex®, <br /> the Home of{" "}
              <span className="text-primaryOrange">Academic Horror</span>
            </h1>
            <div className="items-center grid-cols-2 gap-12 mt-8 md:grid lg:mt-12">
              <div className="col-span-1 prose-lg text-gray-300">
                <p>
                  Horror Lex is a searchable database of academic and other
                  serious writings about horror and horror-adjacent cinema,
                  compiled under the guidance of Professor Lupe Lex, PhD, S.L.W.
                </p>
                <p>
                  Professor Lex has hand-picked over <span className="text-bold">13,000</span> books and articles
                  spanning a century of horror film scholarship, much of which
                  is open access and peer-reviewed.
                </p>
                <p>
                  While horror movie blogs are a dime a dozen, and library
                  catalogs are hit or miss, Horror Lex has curated a collection
                  of quality writings on horror cinema suitable for serious
                  scholars and the intellectually curious.
                </p>
              </div>
              <div className="col-span-1 justify-self-center">
                <img src={introImage} alt="Introducing Professor Lupe Lex" />
              </div>
            </div>
          </section>
        </div>
        {/* <!-- Search Database --> */}
        <section className="max-w-6xl mx-auto">
          <div className="flex flex-col items-center grid-cols-2 gap-12 space-y-6 md:space-y-0 md:grid">
            <div className="order-2 col-span-1 md:order-1 justify-self-center">
              <img src={aboutImage} alt="Introducing Professor Lupe Lex" />
            </div>
            <div className="order-1 col-span-1 prose-lg text-gray-300 md:order-2">
              <h2 className="font-bold hasChillUnderline">
                Search Our Robust Database
              </h2>
              <p>
                Use the Quick Search box at the top of this page for an instant
                view of what Horror Lex can show you.
              </p>
              <p>
                Or, click on Advanced Search to filter based on publication
                title, author, keyword, date, film, etc.
              </p>
              <DesktopNavButton
                to="search"
                text={"Advanced Search"}
                isPrimary={true}
              />
            </div>
          </div>
        </section>
        {/* <!-- Browse Collection --> */}
        <section className="max-w-6xl mx-auto">
          <div className="items-center grid-cols-2 gap-12 space-y-6 md:space-y-0 md:grid">
            <div className="col-span-1 prose-lg text-gray-300">
              <h2 className="font-bold hasChillUnderline">
                Browse the Collection
              </h2>
              <p>
                Not sure where to start? Get inspiration by using the Browse
                button to peruse Horror Lex's <span className="text-bold">7,500</span> indexed films or keywords.
              </p>
              <DesktopNavButton to="browse" text={"Browse"} isPrimary={true} />
            </div>
            <div className="col-span-1 justify-self-center">
              <img src={browseImage} alt="Introducing Professor Lupe Lex" />
            </div>
          </div>
        </section>
        {/* <!-- Learn More --> */}
        <section className="max-w-6xl mx-auto">
          <div className="flex flex-col items-center grid-cols-2 gap-12 space-y-6 md:space-y-0 md:grid">
            <div className="order-2 col-span-1 md:order-1 justify-self-center">
              <img src={learnImage} alt="Introducing Professor Lupe Lex" />
            </div>
            <div className="order-1 col-span-1 prose-lg text-gray-300 md:order-2">
              <h2 className="font-bold hasChillUnderline">
                Learn More about Horror Lex®
              </h2>
              <p>
                Not sure how this all works, or have a question about Horror
                Lex's database? Click About.
              </p>
              <DesktopNavButton to="about" text={"About"} isPrimary={true} />
              <p>Want to ask Professor Lex a question? Click Contact.</p>
              <DesktopNavButton
                to="contact"
                text={"Contact"}
                isPrimary={true}
              />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Home;

