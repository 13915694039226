import React, { useEffect, useState } from "react";
import storyblokInstance from "../utils/storyblok-service";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const BlogDetail = () => {
  const slug = useParams().slug;
  const navigate = useNavigate();
  const [story, setStory] = useState({
    date: "",
    title: "",
    content: "",
    excerpt: "",
  });

  useEffect(() => {
    storyblokInstance
      .get(`cdn/stories/blog/${slug}`)
      .then(
        ({
          data: {
            story: { content },
          },
        }) => {
          let orginalContent = storyblokInstance.client.richTextResolver.render(
            content.Content
          );

          const requiredContentObject = {
            date: new Date(content.Date).toLocaleString("en-us", {
              month: "long",
              year: "numeric",
              day: "numeric",
            }),
            title: content.title,
            content: orginalContent,
            excerpt: content.Excerpt,
          };

          setStory(requiredContentObject);
        }
      )
      .catch((err) => {
        navigate({ pathname: "/blog" });
      });
  }, []);

  useEffect(() => {
    let handleCopyUrl = async () => {
      let slug = window.location.href;
      if (!navigator.clipboard) {
        // Clipboard API not available
        return;
      }
      try {
        await navigator.clipboard.writeText(slug);
        alert("Copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy!", err);
      }
    };

    window.document
      .getElementById("copyToClipboard")
      .addEventListener("click", handleCopyUrl);
  }, []);

  return (
    <div className="blog-detail">
      <div>
        <div className="divide-y divide-gray-600">
          <div className="max-w-6xl px-4 mx-auto mt-8 divide-y divide-gray-600 lg:mt-16 lg:px-8">
            <div className="mx-auto text-lg max-w-prose">
              <span className="block text-base tracking-wide text-center text-gray-500">
                {story.date}
              </span>
              <h1>
                <span className="block mt-2 mb-8 text-2xl font-extrabold leading-8 tracking-tight text-center text-gray-100 sm:text-4xl sm:leading-10">
                  {story.title}
                </span>
              </h1>
            </div>
            <div>
              <div className="relative flex flex-col items-center py-6 overflow-hidden sm:py-16 sm:grid sm:gap-6 sm:grid-cols-12">
                <div className="col-span-8">
                  <div className="relative px-4 sm:px-6 lg:px-8">
                    <div
                      className="mx-auto mt-6 prose prose-lg text-gray-300"
                      dangerouslySetInnerHTML={{ __html: story.content }}
                    ></div>
                  </div>
                </div>
                <hr className="w-11/12 mt-6 border-t border-gray-200 sm:hidden" />
                <div className="self-start col-span-4 px-4 space-y-8 sm:px-0">
                  <div className="flex items-center mt-6">
                    <div className="flex items-center space-x-2">
                      <a href="#">
                        <span className="sr-only">Professor Lex</span>
                        <img
                          className="w-12 h-12 rounded-full"
                          src="/img/lex-avatar.png"
                          alt=""
                        />
                      </a>
                      <h4 className="text-lg font-medium text-gray-50">
                        Professor Lex
                      </h4>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="">
                      <h4 className="text-lg font-medium text-gray-50">
                        Share this post
                      </h4>
                    </div>
                    <div className="flex mt-8 space-x-6">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://horrorlex.com/${slug}`}
                        className="p-2 rounded-full hover:text-primaryOrangeDark bg-primaryOrange"
                        target="_blank"
                        rel="noopener"
                      >
                        <span className="sr-only">Facebook</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                          className="w-6 h-6"
                        >
                          <path
                            fill="#110803"
                            d="M15.1083 6.45834h-3.55V4.86667c-.0117-.11623.0012-.23362.0378-.34455.0366-.11093.0961-.21292.1747-.29936.0786-.08643.1745-.15538.2814-.20237.107-.04699.2226-.07097.3394-.07039h2.5V.616669h-3.6083c-3.27503 0-3.8917 2.500001-3.8917 4.050001v1.79167h-2.5v3.33333h2.5V19.375h4.1667V9.79167h3.2083l.3417-3.33333Z"
                          />
                        </svg>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?text=Read%20this%20Horror%20Lex%20article&url=https://horrorlex.com/${slug}`}
                        rel="canonical"
                        className="p-2 rounded-full text-blackDark hover:text-primaryOrangeDark bg-primaryOrange twitter-share-button"
                        target="_blank"
                      >
                        <span className="sr-only">Twitter</span>
                        <svg
                          className="w-6 h-6"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                      <a
                        id="copyToClipboard"
                        className="p-2 rounded-full cursor-pointer text-primaryOrange hover:text-primaryOrangeDark bg-primaryOrange"
                      >
                        <span className="sr-only">{story.title}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          className="w-6 h-6"
                        >
                          <path
                            stroke="#110803"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10.1723 13.828c.7501.7499 1.7674 1.1712 2.828 1.1712 1.0607 0 2.0779-.4213 2.828-1.1712l4-3.99999c.7287-.75441 1.1318-1.76482 1.1227-2.8136-.0091-1.04879-.4298-2.05204-1.1714-2.79367-.7416-.74164-1.7449-1.16231-2.7937-1.17142-1.0488-.00912-2.0592.39406-2.8136 1.12269l-1.1 1.1m.756 4.89999c-.7501-.74987-1.7673-1.17113-2.828-1.17113-1.06063 0-2.07786.42126-2.82798 1.17113l-4 4c-.38204.369-.68676.8104-.8964 1.2984-.20964.488-.31998 1.0129-.3246 1.544-.00461.5311.09659 1.0578.29772 1.5494.20112.4916.49813.9382.8737 1.3138.37558.3756.82218.6726 1.31377.8737.49158.2011 1.0183.3023 1.54942.2977.53112-.0046 1.056-.1149 1.54401-.3246.48802-.2096.9294-.5143 1.29838-.8964l1.10198-1.101 2.898-8.555Z"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
