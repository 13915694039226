import StoryblokClient from "storyblok-js-client";
const { REACT_APP_STORYBLOK_PUBLIC } = process.env;

class StoryblokService {
  constructor() {
    this.devMode = true; // Always loads draft
    this.token = REACT_APP_STORYBLOK_PUBLIC;
    this.client = new StoryblokClient({
      accessToken: this.token,
      cache: {
        clear: "auto",
        type: "memory",
      },
    });
  }

  getCacheVersion() {
    return this.client.cacheVersion;
  }

  get(slug, params) {
    params = params || {};

    return this.client.get(slug, params);
  }

  getAll() {
    return this.client.getAll("cdn/stories");
  }

  initEditor(reactComponent) {
    if (window.storyblok) {
      window.storyblok.init();
      window.storyblok.on(["change", "published"], () => location.reload(true));

      // this will alter the state and replaces the current story with a current raw story object (no resolved relations or links)
      window.storyblok.on("input", (event) => {
        if (
          event.story.content._uid === reactComponent.state.story.content._uid
        ) {
          reactComponent.setState({
            story: {
              ...event.story,
              content: window.storyblok.addComments(
                event.story.content,
                event.story.id
              ),
            },
          });
        }
      });
    }
  }
}

const storyblokInstance = new StoryblokService();

export default storyblokInstance;
