import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const QuickSearchBar = () => {
  const [searchValue, setSearchValue] = React.useState("");
  const navigate = useNavigate();
  const handleQuickSearch = () => {
    navigate({
      pathname: "/results",
      search: `?title=${encodeURIComponent(searchValue)}&sort=bestMatch`,
    });
  };

  React.useEffect(() => {
    const focusListener = (event: FocusEvent) => {
      setSearchValue("");
    };
    const keyListener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleQuickSearch();
      }
    };
    const searchBar = document.getElementById("quickSearch");
    searchBar.addEventListener("keydown", keyListener);
    searchBar.addEventListener("focus", focusListener);
    return () => {
      searchBar.removeEventListener("keydown", keyListener);
      searchBar.removeEventListener("focus", focusListener);
    };
  }, [searchValue]);

  return (
    <div className="justify-center flex-1 hidden lg:justify-end lg:flex">
      <div className="w-full">
        <label htmlFor="search" className="sr-only">
          Quick Search
        </label>
        <div className="relative text-gray-300 focus-within:text-gray-400">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            id="quickSearch"
            className="block w-full py-2 pl-10 pr-3 leading-5 text-gray-300 placeholder-gray-300 transition duration-150 ease-in-out bg-gray-400 bg-opacity-25 border border-transparent rounded-md focus:outline-none focus:bg-white focus:text-gray-900 sm:text-sm focus:ring-primaryOrange focus:border-primaryOrange"
            type="search"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            value={searchValue}
            placeholder="Quick Search"
          />
        </div>
      </div>
    </div>
  );
};

export const NavBarLogo = (props: { image: string }) => {
  return (
    <div className="flex items-center xl:w-64">
      <div className="flex-shrink-0">
        <Link to="/">
          <img
            className="w-48 sm:w-64"
            src={props.image}
            alt="Horror Lex logo"
          />
        </Link>
      </div>
    </div>
  );
};

export const DesktopNavButtonContainer = (props: { children: any }) => {
  return (
    <div className="hidden lg:block lg:w-bigAss">
      <div className="flex items-center justify-end">
        <div className="flex space-x-6">
          {React.Children.map(props.children, (child) =>
            React.cloneElement(child, { ...props, ...child.props })
          )}
        </div>
      </div>
    </div>
  );
};

export const DesktopNavButton = (props: {
  isPrimary?: boolean;
  to: string;
  text: string;
  forNavbar?: boolean;
}) => {
  const primaryTWStyle = `inline-flex items-center ${
    props.forNavbar ? "px-4" : "px-8"
  } ${props.forNavbar ? "py-2" : "py-2.5"} ${
    props.forNavbar && "text-sm"
  } font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-primaryOrange hover:bg-primaryOrangeDark focus:outline-none focus:shadow-outline-teal focus:border-primaryOrangeDark active:bg-primaryOrangeDark`;
  const secondaryTWStyle =
    "py-2 text-base font-medium leading-5 text-gray-100 transition duration-150 ease-in-out hover:text-primaryOrangeDark focus:outline-none focus:text-white focus:border-primaryOrangeDark";
  let style = props.isPrimary ? primaryTWStyle : secondaryTWStyle;
  return (
    <Link to={props.to}>
      <div className={style}>{props.text}</div>
    </Link>
  );
};

export const MobileNavButtonContainer = (props: {
  handleClose: () => void;
  isMobileOpen: boolean;
  children: any;
}) => {
  const children = React.Children.map(props.children, (child) =>
    React.cloneElement(child, { ...props, ...child.props })
  );
  return (
    <div
      className={`absolute ${
        props.isMobileOpen ? "" : "hidden"
      } lg:hidden inset-x-0 z-10 transform bg-gray-900 rounded-md shadow-lg top-full transition ease-in-out delay-6050`}
    >
      <div className="px-2 pt-2 pb-3">{children}</div>
    </div>
  );
};

export const MobileNavButton = ({
  handleClose,
  text,
  to,
}: {
  handleClose?: () => void;
  text: string;
  to: string;
}) => {
  return (
    <Link to={to}>
      <div
        onClick={handleClose}
        className="block p-2 mt-1 text-base font-medium text-gray-200 transition duration-150 ease-in-out rounded-md hover:text-gray-100 hover:bg-gray-600 focus:outline-none focus:text-white focus:bg-gray-600"
      >
        {text}
      </div>
    </Link>
  );
};

export const MobileMenuButton = ({
  isMobileOpen,
  toggleMobileButton,
}: {
  isMobileOpen: boolean;
  toggleMobileButton: () => void;
}) => {
  return (
    <div className="flex lg:hidden">
      <button
        className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-600 focus:outline-none focus:bg-gray-600 focus:text-white"
        aria-label="Main menu"
        aria-expanded={isMobileOpen ? "true" : "false"}
        onClick={toggleMobileButton}
      >
        <svg
          className="block w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h8m-8 6h16"
          ></path>
        </svg>

        <svg
          className="hidden w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>
    </div>
  );
};
