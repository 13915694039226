import React, { useState, useRef, useEffect } from "react";
import { search } from "../utils/asyncServerRequests";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  RenderNavButton,
  RenderSelectDropDown,
} from "../components/renderers/renderers";
import ResultCard from "../components/ResultCard/ResultCard";
import { createPagination } from "./helpers/ResultsHelpers";

type searchResponse = {
  [type: string]: any;
};

const Results = () => {
  const [results, setSearchResults] = useState<searchResponse>({});
  const [isLoading, setIsLoading] = useState(true);
  const queryString = useLocation().search;
  const decodedQueryString = decodeURIComponent(queryString);
  const lastNavigate = useRef("firstLoad");
  const navigate = useNavigate();
  const query = new URLSearchParams(decodedQueryString);
  const sortOptions = [
    { value: "Best Match", active: true, alias: "bestMatch" },
    { value: "Author", active: false, alias: "author" },
    { value: "Year", active: false, alias: "year" },
    { value: "Title", active: false, alias: "title" },
  ];
  const [currentSortOption, setCurrentSortOption] = useState(
    query.get("sort") &&
      sortOptions.map((each) => each.alias).includes(query.get("sort"))
      ? query.get("sort")
      : "bestMatch"
  );

  const populateSearch = async () => {
    setIsLoading(true);
    const result: searchResponse = await search(queryString);

    if (!result.documents) {
      navigate("/not-found", { replace: true });
    }

    setSearchResults(result);
    setIsLoading(false);
    if (!result) {
      navigate("/not-found", { replace: true });
    }
  };

  useEffect(() => {
    const usp = new URLSearchParams(queryString);
    if (
      lastNavigate.current !== "firstLoad" &&
      lastNavigate.current !== `?${usp.toString()}`
    ) {
      navigate({ pathname: "/results", search: `?${usp.toString()}` });
      lastNavigate.current = `?${usp.toString()}`;
    }
    populateSearch();
  }, [queryString]);

  const onSortOptionsChange = (value: string) => {
    setCurrentSortOption(value);
    const params = Object.fromEntries(new URLSearchParams(queryString));
    params.sort = value;
    delete params.page;
    let newString = new URLSearchParams({
      ...params,
    }).toString();

    if (lastNavigate.current !== `?${newString}`) {
      navigate({ pathname: "/results", search: `?${newString}` });
      lastNavigate.current = `?${newString}`;
    }
  };

  return (
    <main>
      <section className="mx-auto mt-8 lg:mt-16 wrapper">
        <div className="flex items-center justify-between">
          <div className="flex flex-col justify-start space-y-2">
            {results.page && (
              <>
                {results.page.query ? (
                  <h2 className="text-xl font-bold text-white lg:text-2xl">
                    Displaying{" "}
                    <span className="text-primaryRed">
                      {results.page.range} of {results.page.total}
                    </span>{" "}
                    Search Results for "{results.page.query}":
                  </h2>
                ) : (
                  <h2 className="text-xl font-bold text-white lg:text-2xl">
                    Displaying{" "}
                    <span className="text-primaryRed">
                      {`${results.page.skip + 1} - ${
                        results.page.skip + results.page.count >=
                        results.page.total
                          ? results.page.total
                          : results.page.skip + results.page.count
                      }`}{" "}
                      of {results.page.total}
                    </span>{" "}
                    Search Results:
                  </h2>
                )}
              </>
            )}
            <p className="text-gray-100">
              Click a card below to read the full details
            </p>
          </div>
          <div className="hidden lg:block">
            <div className="flex">
              <RenderSelectDropDown
                name="sort-results"
                options={sortOptions}
                onChange={(value) => {
                  onSortOptionsChange(value);
                }}
                value={currentSortOption}
              />
              <RenderNavButton path="/search" text="Revise Search" />
            </div>
          </div>
        </div>
      </section>
      <section className="pt-12 pb-8 mx-auto wrapper">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {!isLoading &&
            results &&
            results.documents?.map((result: any, idx: number) => (
              <ResultCard {...result} key={idx} />
            ))}
        </div>
      </section>
      <section className="px-4 mx-auto wrapper">
        <nav className="flex items-center justify-between px-4 border-t border-gray-800 sm:px-0">
          {results.page && results.page.prev ? (
            <div className="flex flex-1 w-0">
              <Link
                to={`/results?${results.page.params}&page=${results.page.prev}`}
              >
                <div className="inline-flex items-center pt-4 pr-1 -mt-px text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400">
                  <svg
                    className="w-5 h-5 mr-3 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Previous
                </div>
              </Link>
            </div>
          ) : (
            <div className="flex flex-1 w-0"></div>
          )}
          <div className="hidden md:flex">{createPagination(results.page)}</div>
          {results.page && results.page.next ? (
            <div className="flex justify-end flex-1 w-0">
              <Link
                to={`/results?${results.page.params}&page=${results.page.next}`}
              >
                <div className="inline-flex items-center pt-4 pl-1 -mt-px text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400">
                  Next
                  <svg
                    className="w-5 h-5 ml-3 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          ) : (
            <div className="flex justify-end flex-1 w-0">
              <p className="inline-flex items-center pt-4 pl-1 -mt-px text-sm font-medium leading-5 text-gray-700">
                End of results
              </p>
            </div>
          )}
        </nav>
      </section>
    </main>
  );
};

export default Results;
