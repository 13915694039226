import React from "react";
import { useField } from "formik";

import { Link } from "react-router-dom";

export const RenderNavButton = ({
  path,
  text,
}: {
  path: string;
  text: string;
}) => {
  return (
    <span className="inline-flex rounded-md shadow-sm">
      <Link to={path}>
        <button
          type="button"
          className="inline-flex items-center px-8 py-2 text-lg font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-primaryOrange hover:bg-primaryOrangeDark focus:outline-none focus:border-primaryOrangeDark focus:shadow-outline-indigo active:bg-border-primaryOrangeDark"
        >
          {text}
        </button>
      </Link>
    </span>
  );
};
export const RenderButton = (props: any) => {
  return (
    <span className="inline-flex rounded-md shadow-sm">
      <button
        type={props.type || "button"}
        className="px-4 py-2 text-lg font-medium text-gray-600 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
      >
        {props.text}
      </button>
    </span>
  );
};
export const RenderSubmitButton = (props: any) => {
  return (
    <span className="inline-flex rounded-md shadow-sm">
      <button
        type="submit"
        className="inline-flex justify-center px-8 py-2 text-lg font-medium text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-primaryOrange hover:bg-primaryOrangeDark focus:outline-none focus:border-primaryOrange focus:shadow-outline-indigo active:bg-primaryOrange mx-3"
      >
        {props.text}
      </button>
    </span>
  );
};

export const RenderResultKey = (props: any) => {
  return (
    <h4 className="text-xl font-medium text-gray-600 font-body">
      {[props.text]}
    </h4>
  );
};

type renderSelectDropDownProps = {
  options: Array<{ active: boolean; value: string; alias: string }>;
  name: string;
  onChange: (e: string) => void;
  value: string;
};
export const RenderSelectDropDown = ({
  options,
  name,
  onChange,
  value,
}: renderSelectDropDownProps) => {
  return (
    <div className="mr-4">
      <label htmlFor={name} className="sr-only">
        Sort By
      </label>
      <select
        data-handler="sort"
        name={name}
        className="block w-full py-2 pl-3 pr-10 text-base leading-6 border-gray-300 form-select focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {options.map((e, i) => (
          <option key={i} value={e.alias}>
            {e.value}
          </option>
        ))}
      </select>
    </div>
  );
};

interface textFieldPropType {
  label: string;
  name: string;
  placeholder?: string;
  [x: string]: any;
}

export const RenderTextField = ({
  label,
  name,
  ...props
}: textFieldPropType) => {
  // Pulled from Search page styling
  const [field, meta] = useField({ name, ...props });
  return (
    <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
      <label
        htmlFor={name}
        className="block text-lg font-medium text-gray-600 sm:mt-px sm:pt-2"
      >
        {" "}
        {label}{" "}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg rounded-md shadow-sm">
          <input
            id={field.name}
            name={field.name}
            type={props.type || "text"}
            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primaryOrange focus:border-primaryOrange sm:text-sm"
            placeholder={props.placeholder || ""}
            {...field}
          />
        </div>
      </div>
    </div>
  );
};

export const RenderCheckBox = ({
  label,
  name,
  ...props
}: textFieldPropType) => {
  const [field, meta] = useField({ name, ...props, type: "checkbox" });
  return (
    <div className="relative flex items-center">
      <div className="flex items-center h-4">
        <input
          id={name}
          name={name}
          type="checkbox"
          className="w-4 h-4 border-gray-300 rounded text-primaryOrange focus:ring-primaryOrange"
          {...field}
          {...props}
        />
      </div>
      <div className="ml-3 text-sm leading-5">
        <label htmlFor={name} className="text-lg font-medium text-gray-500">
          {label}
        </label>
      </div>
    </div>
  );
};
