import moment from "moment";
import React from "react";
import { Link } from "react-scroll";

const Heading = ({ children }: { children: React.ReactNode }) => (
  <div className="text-center font-semibold text-5xl tracking-wide">
    {children}
  </div>
);

const SubHeading = ({ children }: { children: React.ReactNode }) => (
  <div className="text-center font-semibold text-4xl tracking-wide">
    {children}
  </div>
);

const DescribeTitle = ({ children }: { children: React.ReactNode }) => (
  <div className="text-center font-semibold text-xl tracking-wide my-5">
    {children}
  </div>
);

const Paragraph = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <div className={`text-lg mb-6 ${className}`}>{children}</div>;

const Bold = ({
  children,
  withSpace,
}: {
  children: React.ReactNode;
  withSpace?: boolean;
}) => (
  <span className={`font-semibold ${withSpace && "ml-8"}`}>{children}</span>
);

const HyperLinks = () => (
  <div className="flex flex-col my-10">
    <Link
      to="terms-of-use"
      className="text-lg mb-1 text-[#a0410e] cursor-pointer"
    >
      Terms of Use
    </Link>
    <Link
      to="copyright-and-trademark"
      className="text-lg mb-1 text-[#a0410e] cursor-pointer"
    >
      Copyright and Trademark Infringement Policy
    </Link>
    <Link to="privacy-policy" className="text-lg text-[#a0410e] cursor-pointer">
      Privacy Policy
    </Link>
  </div>
);

const List = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col">
      <div className="font-semibold uppercase">{title}</div>
      <div>{children}</div>
    </div>
  );
};

const TermsOfUse = () => (
  <div id="terms-of-use">
    <SubHeading>Terms Of Use</SubHeading>
    <DescribeTitle>Effective Date November 20, 2022</DescribeTitle>
    <Paragraph>
      These terms of use (as amended from time to time, this “Agreement”)
      governs your (“User” or “you”) access and usage of the Horror Lex website
      https://horrorlex.com (the “Website”). The Website is the property of
      Horror Lex LLC, a New York limited liability company, together with its
      affiliates, parent, subsidiaries, successors and assigns (collectively
      “Horror Lex”), and its licensors. By accessing or using the Website, you
      agree to be bound by this Agreement. These terms of use shall be effective
      as of the date that you first access the Website and thereby you accept
      these terms of use (the “Effective Date”). These terms of use hereby
      incorporate by reference Horror Lex’s Privacy Policy (as amended, the
      “Privacy Policy”), as in effect from time to time as set forth on Horror
      Lex’s website. HORROR LEX{" "}
      <Bold>
        HEREBY RESERVES THE RIGHT TO AMEND, ALTER, MODIFY, REPLACE ORSUSPEND,
        FROM TIME TO TIME IN ITS SOLE DISCRETION, ALL OR ANYPORTION OF THIS
        AGREEMENT AND/OR THE PRIVACY POLICY.
      </Bold>
      User’s use of the Website after changes to this Agreement (or the Privacy
      Policy) are posted on Horror Lex’s website will constitute User’s
      acceptance of any such amendments and modifications.
      <br />
      <br />
      <Bold>
        YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE WEBSITE, YOU
        ARE INDICATING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY
        THESE TERMS, WHETHER OR NOT YOU HAVE REGISTERED WITH THE WEBSITE. IF YOU
        DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE
        WEBSITE.
      </Bold>
    </Paragraph>
    <List title="1. CONTENT DISCLAIMERS (NO RELIANCE)">
      <Paragraph>
        The contents of the Website, including (without limitation),
        information, text, graphics, images, audio, video, illustrations,
        written postings, links, tools, data, interactive features, executable
        files, information obtained from Horror Lex’s licensors, and any other
        material and information contained on the Website, or in newsletters,
        books and products made available by Horror Lex and its licensors from
        time-to-time (collectively “Content”) are for informational purposes
        only and is provided to you on an “AS IS” basis, with “ALL FAULTS” and
        with no representations, warranties or guarantees provided by Horror Lex
        or its licensors or partners. Reliance on any Content is solely at your
        own risk We may update the Content from time to time, but the Content is
        not necessarily complete or up to date. Any of the Content may be out of
        date at any given time, and Horror Lex is under no obligation to update
        such Content.
      </Paragraph>
    </List>
    <List title="2. Permitted Use of Horror Lex’s Website">
      <Paragraph>
        You must be over thirteen (13) years old in order to access the Website
        and/or access any Content provided by Horror Lex through use of the
        Website (collectively, “Services”), provide any personal information, or
        otherwise submit personal information through the Services or Website,
        including, but not limited to, your name or email address. Horror Lex
        will make available to you certain Services. The functionality or form
        of these Services may change without prior notice to you. Horror Lex
        retains the right to expand or create limits on and related to use of
        the Services in its sole discretion at any time with or without notice.
        Horror Lex may also impose limits on certain Services or aspects of
        those Services or restrict your access to parts or all of the Services
        without notice or liability, pursuant to the terms of this Agreement.
        Horror Lex may change, suspend, or discontinue any or all of the
        Services at any time, including the availability of any feature,
        database, or Content.
      </Paragraph>
    </List>
    <List title="3. Prohibited Use of Horror Lex’s Services">
      <Paragraph>
        Horror Lex wants to ensure that certain behaviors do not jeopardize our
        users, threaten our infrastructure or damage our community. As such,
        Horror Lex prohibits the following activities with respect to the use of
        its Services and Website:
        <br />
        <Bold withSpace>a.</Bold> You may not impersonate anyone or create fake
        subscription name and email address; <br />
        <Bold withSpace>b.</Bold> You may not modify, adapt, sub-license,
        translate, sell, reverse engineer, decompile, or disassemble any portion
        of the Website or Services; <br />
        <Bold withSpace>c.</Bold> You may not sublicense, sell, rent, lease,
        transfer, assign, or convey any rights under this Agreement to any third
        party, or otherwise commercially exploit or profit from the information
        or Content provided by Horror Lex through the Website or Service, or any
        portion thereof, in any manner whatsoever; <br />
        <Bold withSpace>d.</Bold> You may not “frame” or “mirror” any part of
        the Website without our prior written authorization; and
        <br />
        <Bold withSpace>e.</Bold> You may not use any robot, spider, site
        search/retrieval application, or other manual or automatic device or
        process to retrieve, index or “data mine” the Website or in any way
        reproduce or circumvent the navigational structure or presentation of
        the Website or its contents, authentication and security measures.
      </Paragraph>
    </List>
    <List title="4. Registration">
      <Paragraph>
        You do not need to register with the Website in order to navigate and
        explore the Website. User acknowledges and agrees that all information
        provided to Horror Lex will be accurate, current and complete. Horror
        Lex reserves the right to terminate this Agreement and User’s use of the
        Website and Services at any time in its sole discretion.
      </Paragraph>
    </List>
    <List title="5. Horror Lex’s Rights">
      <Paragraph>
        Subject to your compliance with this Agreement, Horror Lex grants User a
        limited, non-exclusive, non-transferable, non-sublicensable and
        revocable license to access and make use of the Website and Services in
        accordance with the terms of this Agreement. Horror Lex does not endorse
        or make any warranties or representations with respect to the accuracy,
        fitness, completeness or timeliness of any Content posted on the
        Website.
      </Paragraph>
    </List>
    <List title="6. Third-Party Links">
      <Paragraph>
        Horror Lex’s Website may contain links to third party websites for your
        convenience and it does not indicate any approval or endorsement by
        Horror Lex of any linked website or any product or material contained on
        any linked website. Horror Lex does not control the linked websites or
        the content provided through such websites. Your use of linked websites
        is subject to the privacy practices and terms of use established by the
        specific linked website, and Horror Lex disclaims all liability for such
        use. By accessing any third-party website or content, User does so at
        their own risk and agrees that Horror Lex will have no liability arising
        from User’s use of or access to any third party website or content.
      </Paragraph>
    </List>
    <List title="7. Copyright and Trademark">
      <Paragraph>
        Horror Lex respects third party intellectual property rights. As such,
        Horror Lex developed a Copyright and Trademark Infringement Policy
        providing a means by which third parties who believe their copyright or
        trademark rights have been violated can submit complaints to Horror Lex.
        The Copyright and Trademark Infringement Policy is available
        <Link
          to="copyright-and-trademark"
          className="text-[#a0410e] cursor-pointer"
        >
          {" "}
          below
        </Link>
        .
      </Paragraph>
    </List>
    <List title="8. Ownership">
      <Paragraph>
        The Website is owned and operated by Horror Lex and its licensors, and
        the Services (and any intellectual property and other rights relating
        thereto) are and will remain the exclusive property of Horror Lex and
        its licensors, unless otherwise specifically noted. The Website and the
        Services are protected by U.S. and international copyright, trademark,
        and other laws, and you acknowledge that these rights are valid and
        enforceable. Except as otherwise expressly set forth in this Agreement,
        you may not copy, reproduce, duplicate, modify, adapt, translate,
        republish, upload, post, transmit, distribute, sub-license, sell,
        reverse engineer, decompile, disassemble, use or otherwise exploit for
        any commercial purpose any part of the Website or any Service without
        Horror Lex’s prior written permission. Use of the Website or any
        Services for any other purpose beyond the terms of this Agreement is
        strictly prohibited.{" "}
        <Bold>
          USER ACKNOWLEDGES AND AGREES THAT USER DOES NOT ACQUIRE ANY OWNERSHIP
          RIGHTS BY USING THE WEBSITE OR ANY SERVICES.
        </Bold>{" "}
        <br />
        <br />
        Nothing contained in this Agreement, the Website or the Services should
        be construed as granting, by implication, estoppel, or otherwise, any
        license or right to use any intellectual property of Horror Lex or its
        licensors without the express written permission of Horror Lex or Horror
        Lex’s licensors (respectively).
      </Paragraph>
    </List>
    <List title="9. Term & Termination">
      <Paragraph>
        This Agreement is effective from the Effective Date and shall remain
        effective until terminated in accordance with its terms. Horror Lex may
        immediately terminate this Agreement, and/or your access to and use of
        the Website and Services, or any portion thereof, at any time and for
        any reason, with or without cause, without prior notice or liability.
        This Agreement will also terminate automatically if User fails to comply
        with any term or provision of this Agreement.
        <br />
        <br />
        Horror Lex reserves the right at any time and from time to time to
        modify, discontinue, temporarily or permanently, the Website, the
        Services, or any part or portion thereof, with or without notice to
        User. User agrees that Horror Lex shall not be liable to User or to any
        third party for any modification, suspension or discontinuance of the
        Website or any Services, or any part of portion thereof. Upon
        termination of your access to or ability to use a Service your right to
        use or access such Service will immediately cease. Sections 1, 3, 4, 6,
        7, 8, 9, 10, 11, 12, 13 and 14 shall survive the termination of this
        Agreement.
      </Paragraph>
    </List>
    <List title="10. Disclaimers">
      <Paragraph>
        YOUR USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE WEBSITE, INCLUDING BUT
        NOT LIMITED TO THE CONTENT, THE INFORMATION AND THE SERVICES, ARE
        PROVIDED “AS IS”; AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
        IMPLIED, AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT
        LIMITATION, IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY,
        COMPLETENESS, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ANY
        WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE
        OR USAGE OF TRADE, AND ANY WARRANTIES THAT THE INFORMATION AND WEBSITE
        IS CURRENT AND/OR UP-TO-DATE ARE HEREBY EXPRESSLY DISCLAIMED TO THE
        FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW. THERE IS NO WARRANTY,
        REPRESENTATION OR GUARANTEE THAT THE WEBSITE OR THE SERVICES, OR YOUR
        USE OF THE WEBSITE OR THE SERVICES, WILL BE UNINTERRUPTED, COMPLETE,
        ACCURATE, CURRENT, RELIABLE, ERROR-FREE, SECURE, OR THAT ANY PROBLEMS
        WILL BE CORRECTED, OR THAT THE WEBSITE, OR ANY INFORMATION, SOFTWARE OR
        OTHER MATERIAL ACCESSIBLE FROM THE WEBSITE, IS FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS. WE DO NOT WARRANT, GUARANTEE, OR MAKE ANY
        REPRESENTATION REGARDING THE USE OF, OR THE RESULTS OF THE USE OF THE
        WEBSITE EITHER IN TERMS OF ITS COMPATIBILITY WITH HARDWARE OR OTHER
        SOFTWARE OR EQUIPMENT, AND YOU ASSUME ALL RESPONSIBILITY AND RISK FOR
        YOUR USE OF THE WEBSITE AND/OR SERVICES, CONTENT AND INFORMATION AND
        YOUR RELIANCE THEREON.
      </Paragraph>
    </List>
    <List title="11. Limitation of Liability">
      <Paragraph>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, HORROR LEX AND ITS LICENSORS
        SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
        OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
        INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR
        OTHER INTANGIBLE LOSSES, RESULTING FROM OR RELATING IN ANY MANNER TO THE
        WEBSITE, INFORMATION, SERVICES, CONTENT AND/OR ANY LINKED WEBSITE,
        WHETHER OR NOT HORROR LEX HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH
        DAMAGES OR LIABILITIES. USER’S SOLE REMEDY WITH RESPECT TO THIS WEBSITE,
        THE INFORMATION, SERVICES, CONTENT OR ANY LINKED WEBSITE IS TO STOP
        USING THE WEBSITE, SERVICE, CONTENT, OR LINKED WEBSITE, AS APPLICABLE.
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, USER HEREBY EXPRESSLY WAIVES ANY
        AND ALL CLAIMS AGAINST HORROR LEX AND ITS RESPECTIVE MEMBERS, MANAGERS,
        OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS, LICENSORS,
        LICENSEES, AFFILLIATES, CONSULTANTS, SUCCESSORS AND ASSIGNS THAT MAY
        ARISE FROM USER’S ACCESS OR USE OF THIS WEBSITE OR SERVICES (INCLUDING
        THE CONTENT).
      </Paragraph>
    </List>
    <List title="12. Indemnification">
      <Paragraph>
        User agrees to fully indemnify, defend, and hold Horror Lex, its
        licensors, agents, successors, assigns, members, managers, directors,
        officers, shareholders, employees, consultants, and other
        representatives, harmless from and against any and all claims, damages,
        losses, costs (including reasonable attorneys’ fees), and other expenses
        that arise directly or indirectly out of or from: (a) User’s breach of
        this Agreement; (b) User activities in connection with the Website or
        other websites to which the Website is linked; and/or (c) User’s
        negligence or willful misconduct.
      </Paragraph>
    </List>
    <List title="13. Jurisdictions and Governing Law">
      <Paragraph>
        User agrees that for any dispute User has with Horror Lex, it will
        contact Horror Lex first and attempt to resolve the dispute with us
        informally. TO THE MAXIMUM EXTENT PERMITTED BY LAW, ALL CLAIMS MUST BE
        BROUGHT IN THE PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
        CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. YOU
        AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND HORROR LEX ARE EACH
        WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
        ACTION. This Agreement shall be governed by and construed in accordance
        with the laws of the State of New York, without regard to its choice of
        law provisions. The parties hereby irrevocably submit and consent to the
        jurisdiction of all federal and state courts in New York and hereby
        irrevocably agree that the sole venue for all disputes under this
        Agreement shall lie exclusively in New York County, New York.
      </Paragraph>
    </List>
    <List title="14. Miscellaneous">
      <Paragraph>
        If any provision of this Agreement is found to be unlawful, void, or for
        any reason unenforceable, then that provision shall be deemed severable
        from this Agreement and shall not affect the validity and enforceability
        of any remaining provisions. In such case, the invalid or unenforceable
        provision shall be replaced by a valid, enforceable provision that most
        closely matches the intent of the original provision, and the validity
        and enforceability of any remaining provisions shall not be affected.
        This Agreement, together with the Privacy Policy, is the entire
        agreement between User and Horror Lex relating to the subject matter
        herein and supersedes any and all prior or contemporaneous written or
        oral agreements between the parties with respect to such subject matter.
        This Agreement may not be changed, waived or modified except by Horror
        Lex as provided herein or otherwise by written instrument signed by
        Horror Lex. Neither this Agreement nor any right, obligation, or remedy
        hereunder is assignable, transferable, delegable, or sub-licensable by
        User except with Horror Lex’s prior written consent, and any attempted
        assignment, transfer, delegation, or sublicense shall be null and void.
        Horror Lex may assign, transfer, or delegate this Agreement or any right
        or obligation or remedy hereunder in its sole discretion. No waiver by
        either party of any breach or default hereunder shall be deemed to be a
        waiver of any preceding or subsequent breach or default. Any heading,
        caption, or section title contained in this Agreement is inserted only
        as a matter of convenience and in no way defines or explains any section
        or provision hereof.
      </Paragraph>
    </List>
    <List title="15. Acknowledgment">
      <Paragraph>
        You have independently sought the use of the Website, and Horror Lex has
        not otherwise solicited use of the Website. You are not receiving
        anything of value as inducement to use the Website.
      </Paragraph>
    </List>
    <List title="16. Contact Us">
      <Paragraph>
        Horror Lex welcomes your questions or comments regarding the Terms of
        Use at notices@horrorlex.com.
      </Paragraph>
    </List>

    <div className="pt-8 mt-12 border-t-2" id="copyright-and-trademark">
      <SubHeading>
        <br />
        Copyright and Trademark Infringement Policy
      </SubHeading>
      <Paragraph>
        <br />
        Horror Lex LLC, a New York limited liability company (“Horror Lex”) is
        committed to respecting and protecting the intellectual property rights
        of third parties. To that end, Horror Lex developed this Copyright and
        Trademark Infringement Policy so third parties who believe their
        copyright or trademark rights have been violated can submit copyright
        and trademark complaints to Horror Lex. If you believe in good faith
        that materials hosted by Horror Lex infringe your copyright or trademark
        rights, you (or your agent) may send us a notice requesting that the
        material be removed or access to it be blocked. The notice must include
        the following information:
        <br />
        <Bold withSpace>a.</Bold> Physical or electronic signature of a person
        authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed;
        <br />
        <Bold withSpace>b.</Bold> Identification of the copyrighted work or
        trademark located on https://horrorlex.com (the “Website”) claimed to
        have been infringed, or if multiple copyrighted works or trademarks are
        covered by a single notification, a representative list of such works;{" "}
        <br />
        <Bold withSpace>c.</Bold> identification of the material that is claimed
        to be infringing or the subject of infringing activity, and information
        reasonably sufficient to allow Horror Lex to locate the material on the
        Website; <br />
        <Bold withSpace>d.</Bold> the name, mailing address, telephone number,
        and email address of the complaining party; <br />
        <Bold withSpace>e.</Bold> the following statement: “I have a good faith
        belief that use of the material in the manner complained of is not
        authorized by me, the copyright or trademark owner, its agent, or the
        law”; and <br />
        <Bold withSpace>f.</Bold> the following statement: “the information in
        the notification is accurate and, under penalty of perjury, I am
        authorized to act on behalf of the owner of an exclusive right that is
        allegedly infringed.”
        <br />
        <br /> Please be advised that Horror Lex will not respond to complaints
        that do not meet the above listed requirements. <br />
        <br />
        Copyright or Trademark notices with respect to the Website must meet the
        above-mentioned statutory requirements imposed by the Digital Millennium
        Copyright Act (“DMCA”) and should be sent to the agent identified below.
        We suggest that you consult your legal advisor before filing a notice.
        Also, be aware that there can be penalties for false claims under the
        DMCA.
        <br />
        <br /> Horror Lex reserves the right to amend or modify this policy from
        time to time. Use of Horror Lex’s service or the Website after changes
        to this policy are posted on the Website will constitute a user’s
        acceptance of any such amendments and modifications.
        <br />
        <br /> Agent to Receive Notices of Claimed Infringement:
        <br />
        <br />
        notices@horrorlex.com
        <br />
        <br />
        -or-
        <br />
        <br /> DMCA Complaints - Horror Lex LLC <br />
        c/o Rickner PLLC <br />
        14 Wall Street, Suite 1603
      </Paragraph>
    </div>
  </div>
);

const PrivacyPolicy = () => (
  <div className="pt-10 mt-12 border-t-2" id="privacy-policy">
    <Paragraph>New York, NY 10005</Paragraph>
    <SubHeading>Privacy Policy</SubHeading>
    <DescribeTitle>Published {moment().format("LL")}</DescribeTitle>
    <Paragraph>
      Horror Lex LLC, a New York limited liability company, together with its
      affiliates, parent, subsidiaries, successors and assigns (collectively
      “Horror Lex”) is committed to respecting and protecting the privacy of our
      website visitors, customers and partners. In this Privacy Policy
      (“Policy”) we provide information on when and why we collect your personal
      information, how we use it, the limited conditions under which we may
      disclose it to others, and how we keep it secure. This Policy is
      incorporated by this reference into the Horror Lex Terms of Use located
      <Link to="terms-of-use" className="text-[#a0410e] cursor-pointer">
        {" "}
        above
      </Link>{" "}
      (the “Terms of Use”). Any capitalized terms used in this Policy which are
      not otherwise defined in this Policy shall have the meaning set forth in
      the Terms of Use. By using https://horrorlex.com (our “Website”), you
      agree that your use is subject to the terms of this Policy and you consent
      to the collection, use and storage of your information by Horror Lex
      (together with its affiliates and partners) in accordance with this
      Policy. Horror Lex may change this Policy at any time and will display the
      changes here in the form of a new Policy. If Horror Lex materially changes
      the ways in which it uses or shares personal information previously
      collected from you through the website, we will notify you by email
      (assuming we have previously collected your email address), or other
      communication on our website. Because your use of this website after
      changes have been posted indicates your agreement with those changes, we
      encourage you to visit this Policy on a regular basis. IF YOU ARE NOT
      COMFORTABLE WITH USING THIS WEBSITE IN ACCORDANCE WITH THIS POLICY, PLEASE
      EXIT IMMEDIATELY.
    </Paragraph>
    <List title="What Personal Information May Be Collected?">
      <Paragraph>
        Personal information means any information that may be used to identify
        you, such as your name, phone number, mailing address or email address.
        Horror Lex may collect personal information about you when you request
        information regarding our services.
      </Paragraph>
    </List>
    <List title="How Personal Information May Be Used">
      <Paragraph>
        The information we collect may be used to: (a) provide customer support
        to you; (b) facilitate your use of our website and improve the operation
        of the website; (c) provide marketing materials to you (e.g.,
        newsletters and promotional information); (d) respond to and fulfill any
        requests you make for our services; (e) contact you if we need to obtain
        or provide additional information; and/or (f) verify the accuracy of the
        information provided for our records. Other uses of your personal
        information may be evident from the particular webpage or point at which
        we collect the information. Horror Lex will retain personal information
        collected for as long as it is needed to fulfill the purpose for which
        the information was collected or as otherwise permitted or required by
        applicable law.
      </Paragraph>
    </List>
    <List title="Disclosure of Personal Information to Third Parties">
      <Paragraph>
        Horror Lex will not sell or give your personal information to third
        parties for their use for purposes unrelated to Horror Lex’s services
        without your permission, except as noted in this Policy, the Terms of
        Use, or on the Website. We may share personal information with our
        suppliers, contractors and agents who perform functions on our behalf,
        with other third-party business partners, or in connection with services
        offered by Horror Lex or a request made by you. By way of example (and
        not limitation), we may use third-party content management systems and
        customer relationship management systems in connection with the Website.
        In this instance, personal information shall be collected and retained
        in accordance with our third-party service provider’s own terms and
        conditions and privacy policy (in addition to or in lieu of Horror
        Lex’s). Other examples of third-party service providers may include
        hosting services and audio or video hosting providers, to the extent
        being used on the Website. By using our services, you authorize Horror
        Lex to sub-contract in this manner on your behalf; you acknowledge that
        such services are provided in accordance with third-party terms and
        conditions and privacy policies, and you agree to abide by such
        third-party terms and conditions and privacy policies. In rare
        circumstances, we may share information if required by law, or in a
        corporate restructuring or acquisition context (see below for more
        details). Horror Lex may also disclose your personal information as we
        consider necessary to comply with the law or requests by governmental
        authorities, in connection with litigation, dispute resolution, or
        administration of this Policy or a possible violation of the rights of
        Horror Lex or a third party. In addition, Horror Lex may disclose
        personal information in connection with a possible sale, merger,
        transfer, exchange, purchase, disposition or other reorganization of
        Horror Lex and/or its subsidiaries or to (1) conform to legal
        requirements or comply with legal process served on Horror Lex or this
        Website; (2) protect and defend the rights or property of Horror Lex and
        this Website; (3) enforce its agreements with you, or (4) act in urgent
        circumstances to protect personal safety or the public.
      </Paragraph>
    </List>
    <List title="Children and Privacy">
      <Paragraph>
        Horror Lex does not knowingly or intend to solicit or collect personal
        information from children under the age of 13. If you are under 13,
        please do not access our website. In the event that we learn that we
        have collected personal information from a child under age 13 without
        verification of parental consent, we will delete that information as
        quickly as possible. If you believe that we might have any information
        from or about a child under 13, please contact us at
        notices@horrorlex.com.
      </Paragraph>
    </List>
    <List title="Security of Personal Information">
      <Paragraph>
        To protect the personal information we receive, Horror Lex uses a secure
        server when you submit any form requiring registration. The secure
        server software (SSL) helps protect your information as it travels over
        the Internet by encrypting that information before it is sent to us. We
        have also implemented certain reasonable security mechanisms and
        procedures to protect data from loss, misuse and unauthorized access,
        disclosure, alteration and destruction. It should be noted, however,
        that we cannot guarantee that transmission of data over the Internet
        will be absolutely secure. Due to the open nature of the Internet, the
        information you provide may be in environments that do not have adequate
        security measures and may be accessed and used by persons other than
        those intended. As a result, while protection of your personal
        information is important to us, Horror Lex is not able to ensure the
        security of information you transmit to us and you assume the risk of
        any third party obtaining that information. Upon receipt of your
        information Horror Lex will strive to ensure its security on our systems
        through reasonable means. However, please be aware that the efforts we
        exert to secure your data do not in any way constitute a warranty that
        they are absolutely protected from unauthorized access. We are not
        responsible for the breach by third parties of the safeguards we put in
        place to protect the privacy of your data.
      </Paragraph>
    </List>
    <List title="Use of Cookies">
      <Paragraph>
        When you visit the Website, we (or our partners on our behalf), may
        collect information through the use of a “cookie”. A cookie is a small
        amount of data transferred to your browser and read by the web server
        that placed it there. It works as a sort of identification card,
        recording your preferences and previously entered information. This type
        of technology is designed to tell us when you reenter our website, where
        you visit within our website, and the type of browser you use. We may
        use both session cookies and persistent cookies. A session cookie
        disappears after you close your browser. A persistent cookie remains
        after you close your browser and may be used by your browser on
        subsequent visits to the service. You are not required to accept any
        cookie we might use, although some areas of the website may not be
        operational without the use of cookies. You can disable cookies on your
        computer by changing the settings in your browser. The information
        collected helps us identify data relating to usage and trends, address
        any problems with website administration, and to further improve this
        website. We (either directly, or through our partners such as Hotjar)
        also may automatically collect certain information relating to you, such
        as your Internet Protocol (IP) address, Internet service provider, type
        of operating system, the time and date you accessed the website, the
        Uniform Resource Locator (URL) from which you arrived at our website
        and, if you browse away from our website by clicking on a link on our
        website, the URL you click on. Please review your web browser “Help”
        file to learn the proper way to modify your cookie settings. Hotjar
        collects and processes certain analytics data. Hotjar provides some
        additional privacy options described at
        https://www.hotjar.com/policies/do-not-track/regarding cookies.
      </Paragraph>
    </List>
    <List title="Third Party Websites/Services">
      <Paragraph>
        Our website may contain access to: (a) websites and services; and (b)
        links to information on other websites, which are not owned, operated or
        maintained by Horror Lex. We do not have any control over these other
        websites, and therefore we cannot be responsible for the protection and
        privacy of any information that you provide while using or interacting
        with those websites/services/links. If you choose to use or access those
        websites/services/links, please review the privacy policy applicable to
        each website/service. We make third party websites/services available as
        a convenience to you and Horror Lex is not responsible for either the
        privacy policies and/or information collection in connection with any
        third-party websites/services, or for the third-party websites/services
        themselves.
      </Paragraph>
    </List>
    <List title="Communication">
      <Paragraph>
        By providing personal information to us, you agree that we can
        communicate with you electronically regarding any and all issues
        concerning your use of the Horror Lex website.
      </Paragraph>
    </List>
    <List title="Access to Your Personal Information">
      <Paragraph>
        You are entitled to access the personal information that we hold. Please
        email your request to notices@horrorlex.com.
      </Paragraph>
    </List>
    <List title="Your California Rights">
      <Paragraph>
        If you are a California resident, California Civil Code Section 1798.83
        permits you to request information regarding the disclosure of your
        Personal Information (as defined by California Law) to third parties for
        the third parties’ direct marketing purposes. To make such a request,
        please email Horror Lex at notices@horrorlex.com, or write to Horror Lex
        c/o Rickner PLLC at 14 Wall Street, Suite 1603, New York, NY 10005.
      </Paragraph>
    </List>
    <List title="Unsubscribing">
      <Paragraph>
        If you don’t want to receive informational or promotional materials from
        Horror Lex or our marketing partners, you can opt out at any time, by
        emailing notices@horrorlex.com with “Unsubscribe” and your email address
        in the subject line.
      </Paragraph>
    </List>
    <List title="Additional Questions">
      <Paragraph>
        If you have any questions about our Privacy Policy, the information that
        we have collected from you online, the practices of the Website, or your
        interactions with the Website, please email us at notices@horrorlex.com
        or write to us c/o Rickner PLLC at 14 Wall Street, Suite 1603, New York,
        NY 10005.
        <br />
        <br />
        Depending where you live, you may have the right to lodge a complaint
        with a supervisory authority or other regulatory agency if you believe
        we have violated any rights concerning your personal information. We
        encourage you to first reach out to us so we have an opportunity to
        address your concerns directly before you do so.
      </Paragraph>
    </List>
  </div>
);

const Notice = () => {
  return (
    <div className="text-white p-14 px-28">
      <Heading>Legal Notice</Heading>
      <HyperLinks />
      <TermsOfUse />
      <PrivacyPolicy />
    </div>
  );
};

export default Notice;

