import React, { useEffect, useState } from "react";
import storyblokInstance from "../utils/storyblok-service";
import axios from "axios";
import { Link } from "react-router-dom";
import { getStories } from "../utils/asyncServerRequests";

const Blog = () => {
  const perPage = 12;
  const [pageNo, setPageNo] = useState(1);
  const [posts, setPosts] = useState([]);
  const [totalCount, setTotalCount] = useState(null);

  const lastPageNo = Math.ceil(totalCount / perPage);

  const fetchBlogs = async () => {
    const { stories, total } = await getStories(perPage, pageNo);
    setTotalCount(total);
    setPosts(stories);
  };

  useEffect(() => {
    fetchBlogs();
    window.scrollTo(0, 0);
  }, [pageNo]);

  if (!totalCount) return null;

  return (
    <main className="flex flex-col h-full px-4 lg:px-8">
      <section className="w-full pb-8 mx-auto mt-8 border-b border-gray-600 lg:mt-16 max-w-7xl">
        <div className="justify-between lg:items-center">
          <h1 className="text-3xl font-extrabold text-gray-100 sm:text-4xl sm:leading-10">
            The Horror Lex® Blog
          </h1>
        </div>
      </section>
      <section className="flex-1 mx-auto mt-8 lg:mt-8 max-w-7xl">
        <div className="grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          {posts.map((each, index) => {
            return (
              <div key={index}>
                <p className="text-sm text-gray-500">
                  <time dateTime="2021-10-31">
                    {new Date(each.content.Date).toLocaleString("en-us", {
                      month: "long",
                      year: "numeric",
                      day: "numeric",
                    })}
                  </time>
                </p>
                <a href={each.content.slug} className="block mt-2">
                  <h4 className="text-xl font-semibold text-white">
                    {each.name}
                  </h4>
                  <p className="mt-3 text-base text-gray-300">
                    {each.content.Excerpt}
                  </p>
                </a>
                <div className="mt-3">
                  <Link
                    to={`${each.slug}`}
                    className="text-base font-semibold text-primaryOrange hover:text-primaryOrangeDark"
                  >
                    Read full post
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className="flex justify-between flex-none w-full pt-32 mx-auto max-w-7xl">
        {pageNo > 1 && (
          <div className="mt-3">
            <button
              className="text-base font-semibold text-primaryOrange hover:text-primaryOrangeDark"
              onClick={() => {
                setPageNo((currentPage) => {
                  if (currentPage > 1) {
                    return currentPage - 1;
                  }
                });
              }}
            >
              Previous Page
            </button>
          </div>
        )}

        {pageNo !== lastPageNo && (
          <div className="mt-3">
            <button
              className="text-base font-semibold text-primaryOrange hover:text-primaryOrangeDark"
              onClick={() => {
                setPageNo((currentPage) => {
                  return currentPage + 1;
                });
              }}
            >
              Next Page
            </button>
          </div>
        )}
      </section>
    </main>
  );
};

export default Blog;
