import React from "react";
import { Link } from "react-router-dom";

export const createPagination = (pageObj: any) => {
  const pageBuffer = 5;
  const activeStyle =
    "inline-flex items-center px-4 pt-4 -mt-px text-sm font-medium leading-5 transition duration-150 ease-in-out border-t-2 border-primaryOrangeDark text-primaryOrange focus:outline-none focus:text-indigo-800 focus:border-indigo-700";
  const inactiveStyle =
    "inline-flex items-center px-4 pt-4 -mt-px text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out border-t-2 border-transparent hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400";
  const elipsisStyle =
    "inline-flex items-center px-4 pt-4 -mt-px text-sm font-medium leading-5 text-gray-500 border-t-2 border-transparent";
  let pageArray = [<></>];
  if (pageObj) {
    let { pages, curr } = pageObj;
    let start = Math.ceil(pageBuffer / 2);
    let end = Math.ceil(pageBuffer / 2);
    if (curr - start <= 0) {
      pages = [...pages.slice(0, curr + end), "...", pages[pages.length - 1]];
    } else if (curr + end > pages.length - 1) {
      pages = [1, "...", ...pages.slice(pages.length - pageBuffer)];
    } else {
      pages = [
        1,
        "...",
        ...pages.slice(curr - start, curr + end - 1),
        "...",
        pages[pages.length - 1],
      ];
    }
    pageArray = pages.map((page: number | string, idx: number) => {
      if (page === "...") {
        return (
          <div key={idx}>
          <span className={elipsisStyle}>
            {page}
          </span>
          </div>
        );
      }
      return (
        <Link to={`/results?${pageObj.params}&page=${page}`} key={idx}>
          <span
            className={page === curr ? activeStyle : inactiveStyle}
          >
            {page}
          </span>
        </Link>
      );
    });
  }
  return <>{pageArray}</>;
};
