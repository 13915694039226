import React from 'react';

import { Link } from 'react-router-dom';

const errorImage = require('../assets/img/zombies.jpg')

type ErrorProps = {
  status?: number,
  message?: string,
}

const Error = ({ status }: ErrorProps) => {
  const stringCodes: {[key:number]:string} = {
    400: "Bad Request Error",
    404: "Page Not Found",
    500: "Internal Server Error",
    666: "Still under Developement!"
  }
  return (
    <main className="mt-4 lg:mt-10">
      <section className="space-y-4 text-center">
        <h2 className="text-3xl text-white">{stringCodes[status] ? stringCodes[status]: "Whoops!"}</h2>
        <p className="text-lg text-gray-100">This link has been eaten by zombies.</p>
        <img src={errorImage} alt="Female zombie" width="700" className="mx-auto" />
          <p className="text-lg text-gray-100">Try another search in the quick search bar above, <br/> or give our
          <Link to='/search'>
            <div className="text-primaryOrange hover:text-primaryOrangeDark">advanced search</div>
          </Link>
          feature a try.</p>
      </section>

    </main>
  )
}

export default Error;