import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  MobileMenuButton,
  MobileNavButton,
  MobileNavButtonContainer,
  DesktopNavButtonContainer,
  DesktopNavButton,
  NavBarLogo,
  QuickSearchBar,
} from "./NavComponents";

import ReactGA from 'react-ga';
const logoImage = require("../../assets/img/logo/logo-horizontal.png");

const NavBar = () => {
  const [isMobileOpen, setMobileOpen] = React.useState(false);
  const toggleMobileButton = () => {
    setMobileOpen((prev) => !prev);
  };

  const location = useLocation();
  React.useEffect(() => {
   console.log('-');
    ReactGA.pageview(window.location.pathname + window.location.search);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <nav id="mainNav" className="flex-shrink-0 bg-transparent wrapper">
      <div className="max-w-full py-2 mx-auto">
        <div className="relative flex items-center justify-between h-16 space-x-2">
          {/* <!-- Logo section --> */}
          <NavBarLogo image={logoImage} />
          {/* <!-- Search section --> */}
          <QuickSearchBar />
          {/* Mobile Navigation section */}
          <MobileMenuButton
            isMobileOpen={isMobileOpen}
            toggleMobileButton={toggleMobileButton}
          />
          <MobileNavButtonContainer
            isMobileOpen={isMobileOpen}
            handleClose={toggleMobileButton}
          >
            <MobileNavButton to="search" text={"Advanced Search"} />
            <MobileNavButton to="browse" text={"Browse"} />
            <MobileNavButton to="about" text={"About"} />
            <MobileNavButton to="contact" text={"Contact"} />
            <MobileNavButton to="blog" text={"Blog"} />
            <MobileNavButton to="search/test" text={"Test Nest"} />
          </MobileNavButtonContainer>
          {/* <!-- Desktop Links section --> */}
          <DesktopNavButtonContainer>
            <DesktopNavButton
              to="search"
              isPrimary={true}
              text="Advanced Search"
              forNavbar={true}
            />
            <DesktopNavButton to="browse" text="Browse" forNavbar={true} />
            <DesktopNavButton to="about" text="About" forNavbar={true} />
            <DesktopNavButton to="contact" text="Contact" forNavbar={true} />
            <DesktopNavButton to="blog" text="Blog" forNavbar={true} />
          </DesktopNavButtonContainer>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
