import axios from "axios";

const apiWorker = axios.create({
  baseURL: 'https://horrorlex-backend.herokuapp.com',
});

// const apiWorker = axios.create({
//   baseURL: 'http://localhost:4000',
// });

export const search = async (queryString?: string) => {
  let url = "/api/results" + queryString;

  try {
    let { data } = await apiWorker.get(url);
    return data;
  } catch (error) {
    return error;
  }
};
export const browse = async (queryString?: string) => {
  let url = "/api/browse" + queryString;
  try {
    let { data } = await apiWorker.get(url);
    return data;
  } catch (error) {
    return error;
  }
};

export const getPublication = async (
  slug: string,
  author: string,
  id: string
) => {
  let url;
  if (id) {
    url = "/api/pubs/" + slug + "/" + author + "/" + id;
  } else {
    url = "/api/pubs/" + slug + "/" + author;
  }
  try {
    let { data } = await apiWorker.get(url);
    if (!data._id) {
      url = "/api/pubs/" + slug + "/" + author;
      let { data: newData } = await apiWorker.get(url);
      return newData;
    }
    return data;
  } catch (error) {
    return error;
  }
};

export const getStories = async (perPage: number, pageNo: number) => {
  try {
  let {
      data: { stories },
      headers: {total}
    } = await axios.get(
      `https://api.storyblok.com/v2/cdn/stories?per_page=${perPage}&page=${pageNo}&token=6oD0qhB6fbfyBN5c284Chwtt`
    );
    return { stories, total };
  } catch (error) {
    return error;
  }
};
