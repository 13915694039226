import React from "react";


const contactImage = require('../assets/img/contact.png')

const Contact = () => {
  return (
    <main>
      <section className="px-8 mx-auto mt-8 lg:mt-16 max-w-7xl">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 prose-lg text-gray-300 lg:col-span-7">
            <h1
              className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-gray-100 sm:text-4xl sm:leading-10"
            >
              Contact Professor Lex
            </h1>
            <p>
              Reach out to Professor Lex for comments and questions about the site, to suggest additions to the
              database, or if you’ve spotted an error.
            </p>
            <p>
              In addition, Professor Lex is happy to chat with you about your research on horror and horror-adjacent
              films and get you heading in the right direction.
            </p>
            <p>Email: professor@horrorlex.com</p>
          </div>
          <div className="col-span-12 lg:col-span-5">
            <figure>
              <div className="relative pb-7/12 lg:pb-0">
                <img
                  src={contactImage}
                  alt="Professor Lupe Lex writing with his quill and paper"
                  width="1184"
                  height="1376"
                  className="inset-0 object-cover object-center w-full h-full rounded-lg lg:h-auto"
                />
              </div>
            </figure>
          </div>
        </div>
      </section>
    </main>
  )
}
export default Contact;