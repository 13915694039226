import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Home from "./views/Home";
import Article from "./views/Article";
import NavBar from "./components/Navigation/NavBar";
import Footer from "./components/Footer/Footer";
import About from "./views/About";
import Browse from "./views/Browse";
import Search from "./views/Search";
import Results from "./views/Results";
import Error from "./views/Error";
import Contact from "./views/Contact";
import Blog from "./views/Blog";
import Book from "./views/Book";
import BlogDetail from "./views/BlogDetail";
import ReactGA from "react-ga";
import Notice from "./views/Notice";

// dotenv.config();
const App = () => {
useEffect(() => {
    ReactGA.initialize("G-5EF85JJT66");
  }, []);
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="articles" element={<Article />} />
        <Route path="contact" element={<Contact />} />
        <Route path="search" element={<Search />} />
        <Route path="browse/film/:id" element={<Browse />} />
        <Route path="browse/category/:id" element={<Browse />} />
        <Route path="browse" element={<Browse />} />
        <Route path="results/:slug/:author/:id" element={<Book />} />
        <Route path="results/:slug/:author/" element={<Book />} />
        <Route path="results" element={<Results />} />
        <Route path="blog/:slug" element={<BlogDetail />} />
        <Route path="blog" element={<Blog />} />
 <Route path="notice" element={<Notice />} />
        <Route path="not-found" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
