import React from "react";
import {Formik, Form} from 'formik';
import { useNavigate, createSearchParams } from "react-router-dom";

import * as Yup from 'yup';

import { RenderTextField, RenderCheckBox, RenderButton, RenderSubmitButton } from "../components/renderers/renderers";

const Search = () => {
  const navigate = useNavigate();
  const initialFormik = {
    title:'',
    author:'',
    isbn13: '',
    year:'',
    tags:'',
    films:'',
    book:false,
    article:false,
    muse:false,
    jstor:false,
    freeOnlineCopy: false,
    summary: false,
    sort:"bestMatch"
  }

 const validationSchema = Yup.object({
    title: Yup.string(),
    author: Yup.string(),
    isbn13: Yup.string(),
    year: Yup.number(),
    tags: Yup.string(),
    films: Yup.string(),
    book: Yup.boolean(),
    article: Yup.boolean(),
    jstor: Yup.boolean(),
    freeOnlineCopy: Yup.boolean(),
    sort: Yup.string().required()
  });

  const onSubmit = (values:any, formBag:any) => {
    formBag.setSubmitting(true);
    const cleanValues = JSON.parse(JSON.stringify(values))
    for(let key in cleanValues) {
      if(['', false].includes(cleanValues[key])) { delete cleanValues[key] }
    }
    navigate({pathname:'/results', search:`?${createSearchParams(cleanValues)}`})
  }

  return (
    <main className="mx-auto mt-8 space-y-6 lg:mt-16 wrapper">
      <section className="flex justify-between max-w-5xl mx-auto">
        <div className="flex flex-col">
          <h1 className="text-3xl font-bold text-white">Advanced Search</h1>
        </div>
      </section>
      <section className="max-w-5xl px-6 py-6 mx-auto rounded shadow-md bg-blackLight">
        <Formik initialValues={initialFormik} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form >
          <div>
            <div className="pt-8 mt-8 sm:mt-5 sm:pt-2">
              <div>
                <h3 className="text-2xl font-bold leading-6 text-gray-400">Search Criteria</h3>
              </div>
              <div className="mt-6 sm:mt-5">
                <RenderTextField label="Title" name="title" placeholder="Title of book or article"/>
                <RenderTextField label="Author" name="author" placeholder="Primary Author"/>
                <RenderTextField label="ISSN or ISSB-13" name="isbn13"/>
                <RenderTextField label="Publication Year" name="year" type="number"/>
                <RenderTextField label="Keywords" name="tags" placeholder="Comma seperated tag list"/>
                <RenderTextField label="Film" name="films" placeholder="Films used for reference"/>
              </div>
            </div>
            <div className="pt-8 mt-8 sm:mt-5 sm:pt-10">
              <div>
                <h3 className="text-2xl font-bold leading-6 text-gray-400">Limit Your Search</h3>
              </div>
              <div className="mt-6 sm:mt-5">
                <div className="sm:pt-5">
                  <div role="group" aria-labelledby="label-email">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div>
                        <div className="block text-lg font-medium text-gray-600 sm:mt-px sm:pt-2" id="label-email">
                          Type of Publication
                        </div>
                      </div>
                      <div className="mt-4 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg">
                          <RenderCheckBox label="Books" name="book" />
                          <div className="mt-4">
                            <RenderCheckBox label="Articles" name="article" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 sm:mt-5">
                <div className="sm:pt-5">
                  <div role="group" aria-labelledby="label-email">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div>
                        <div className="block text-lg font-medium text-gray-600 sm:mt-px sm:pt-2" id="label-email">
                          Availability
                        </div>
                      </div>
                      <div className="mt-4 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg">
                          <RenderCheckBox label="Free Online Copy" name="freeOnlineCopy" />
                          <div className="mt-4">
                            <RenderCheckBox label="JSTOR" name="jstor" />
                          </div>
                          <div className="mt-4">
                          <RenderCheckBox label="Project MUSE" name="muse" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 sm:mt-5">
                <div className="sm:pt-5">
                  <div role="group" aria-labelledby="label-email">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                      <div>
                        <div className="block text-lg font-medium text-gray-600 sm:mt-px sm:pt-2" id="label-email">
                          Professor Lex Wisdom
                        </div>
                      </div>
                      <div className="mt-4 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg">
                          <RenderCheckBox label="HorrorLex Summary Available" name="summary"/>
                          <div className="mt-4">
                            <RenderCheckBox label="HorrorLex Recommended" name="recommended"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5 mt-8">
            <div className="flex justify-end">
              <RenderButton text="Reset" type="reset" />
              <RenderSubmitButton text="Search" type="submit" />
            </div>
          </div>
        </Form>
        </Formik>
      </section>
    </main>
  )
}

export default Search;