import * as React from "react";

// interface ArticleProps {
//   article: {
//     title: String,
//     author:String,
//     publication: String,
//     year: String,
//     mla8Citation: String,
//   }
// }

const Article = () => {
  return (
    <main className="mx-auto mt-8 space-y-6 lg:mt-24 wrapper">
      <section className="grid grid-cols-12 gap-8">
        {/* <div className="flex flex-col col-span-12 space-y-2 lg:col-span-9">
          <h1 className="text-xl italic font-bold text-white lg:text-3xl">{{article.title}}</h1>
          <div className="flex flex-col space-y-2 lg:items-center lg:space-y-0 lg:space-x-8 lg:flex-row">
              <div className="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.684 23.25H1.824a1.077 1.077 0 01-1.078-1.076V1.826A1.077 1.077 0 011.824.75h15.094A1.077 1.077 0 0118 1.826v5.381M7.203 6.375h6.456" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.805 6a.375.375 0 11-.375.375A.375.375 0 013.805 6M3.805 9.861a.375.375 0 11-.375.375.375.375 0 01.375-.375M15.929 11.352a3.864 3.864 0 00-.511-.307l-1.182-.591a2.071 2.071 0 00-2.129.167l-1.845 1.318A2.609 2.609 0 009.2 13.691l-.279 1.958M23.246 22.5l-5.355-8.564" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.707 16.5l5.571-3.413a1.614 1.614 0 10-1.687-2.753L7.5 16.519l-1.911 3.063 3.6-.311L11.246 18" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16.5 16.5h-3.75a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 001.5 1.5h2.742l1.758 3.75" />
                  </svg>
                <p className="font-medium text-gray-300 lg:text-lg">{{article.author}}</p>
              </div>
              <div className="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 16 16" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.086 15.5H1.5c-.26522 0-.51957-.1054-.707107-.2929C.605357 15.0196.5 14.7652.5 14.5v-13c0-.26522.105357-.51957.292893-.707107C.98043.605357 1.23478.5 1.5.5h13c.2652 0 .5196.105357.7071.292893.1875.187537.2929.441887.2929.707107v7.586c-.0001.26503-.1053.5192-.2927.70667L9.79267 15.2073c-.18747.1874-.44164.2926-.70667.2927v0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.5 15.41V10.5c0-.2652.10536-.51957.29289-.70711C9.98043 9.60536 10.2348 9.5 10.5 9.5h4.91M4.5 4.5h8M4.5 7.5H8" />
                </svg>
                <p className="font-medium text-gray-300 lg:text-lg">Article</p>
              </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-3 lg:justify-self-end">
          {{> ui/ctaBtn link='/search' text='Revise Search'}}
        </div> */}
      </section>
      {/* <section className="px-6 py-10 rounded shadow-md bg-blackLight">
        <!-- Top Half-->
        <div className="grid grid-cols-12 gap-9">
          <div className="grid grid-flow-row col-span-8 space-y-6 auto-rows-max">
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
              <div className="space-y-1">
                {{> ui/resultKey text='Publication'}}
                  /* <p className="text-lg font-medium text-gray-300">{{article.publication}}</p>
              </div>
              <div className="space-y-1">
                {{> ui/resultKey text='Year'}}
                <p className="text-lg font-medium text-gray-300">{{article.year}}</p>
              </div>
              <div className="space-y-1">
               {{> ui/resultKey text='MLA 8 Citation'}}
                <p className="text-lg font-medium text-gray-300">{{{
                  italicizeMLA8
                  article.mla8Citation
                  article.publication}}}
                </p>
              </div>
              <div className="space-y-1">
                {{> ui/resultKey text='DOI/Stable URL'}}
                {{#isUrl article.doiStableUrl}}
                <a
                  href="{{article.doiStableUrl}}"
                  target="_blank"
                  rel="noopener"
                  className="text-lg font-medium text-primaryOrange"
                  >
                  {{article.doiStableUrl}}
                </a>
                {{else}}
                <p className="text-lg font-medium text-gray-800">{{article.doiStableUrl}}</p>
                {{/isUrl}}
              </div>
              <div className="space-y-1">
                {{> ui/resultKey text='Free Online Copy'}}
                {{#if article.freeOnlineCopy}}
                <ul>
                  {{#each article.freeOnlineCopy}}
                  <li>
                    {{#isUrl this}}
                    <a
                      href="{{this}}"
                      target="_blank"
                      rel="noopener"
                      className="text-lg font-medium underline text-primaryOrange hover:text-primaryOrangeDark"
                      >Online copy available here
                    </a>
                    {{else}}
                    <p className="text-lg font-medium text-gray-800">{{this}}</p>
                    {{/isUrl}}
                  </li>
                  {{/each}}
                </ul>
                {{else}}
                <p className="text-lg font-medium text-gray-800">Not available</p>
                {{/if}}
              </div>
              <div className="space-y-1">
                {{> ui/resultKey text='Reprinted In'}}
                <p className="text-lg font-medium text-gray-300">{{article.reprintedIn}}</p>
              </div>
            </div>
          </div>
          <div className="hidden col-span-3 lg:block">
            <img
              src="https://horror-lex.nyc3.cdn.digitaloceanspaces.com/articles/{{article.photo}}.jpg"
              alt="{{article.title}}"
              className="mx-auto"
            />
          </div>
        </div>
        <div className="mt-6">
          <div className="space-y-2">
            {{> ui/resultKey text='For More Information'}}
            <div className="flex items-center justify-start space-x-8">
              {{#if article.muse}}
              <a href="{{article.muse}}" target="_blank" rel="noopener" className="inline-block">
                <img src="/img/logo/muse.png" alt="Muse Logo" className="w-40" />
              </a>
              {{else}}
              <p className="text-lg font-medium text-gray-800">Muse - Not available</p>
              {{/if}}
              {{#if article.jstor}}
              <a href="{{article.jstor}}" target="_blank" rel="noopener" className="inline-block">
                <img src="/img/logo/jstor.png" alt="JSTOR Logo" className="w-40" />
              </a>
              {{else}}
              <p className="text-lg font-medium text-gray-800">JSTOR - Not available</p>
              {{/if}}
            </div>
          </div>
        </div>
        <hr className="my-16" />
        <!-- Bottom Half-->
        <div className="space-y-8">
          <div className="max-w-3xl space-y-2">
            {{> ui/resultKey text='Horror Lex Summary'}}
            <p className="text-lg text-gray-300">{{article.summary}}</p>
          </div>
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-12">
            <div className="col-span-5 space-y-2">
              {{> ui/resultKey text='Films Discussed'}}
              {{#each article.films}}
                <p className="text-base font-normal text-gray-400">
                  <a href="/results?count=10&page=1&query={{uriEncode this}}&sort=title&type=film"className="block">
                    {{this}}
                  </a>
                </p>
              {{else}}
                <p className="text-lg font-medium text-gray-800">Not available</p>
              {{/each}}
            </div>
            <div className="col-span-7 space-y-2">
              {{> ui/resultKey text='Tags'}}
              {{#each article.tags}}
                <a
                  href="/results?count=10&page=1&query={{this}}&sort=title&type=tag"
                  className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-800"
                >
                  {{this}}
                </a>
              {{else}}
              <p className="text-lg font-medium text-gray-800">Not available</p>
              {{/each}}
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
};

export default Article;
