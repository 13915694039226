import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { browse } from "../utils/asyncServerRequests";

const Browse = () => {
  const { pathname } = useLocation();
  const isCategory = pathname.includes("category");
  const [isByKeyword, setIsByKeyword] = useState(isCategory);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState([]);
  const myId = useParams().id;
  let path: "category" | "film" = isByKeyword ? "category" : "film";

  const populateResults = async () => {
    setIsLoading(true);
    const results = await browse(`/${path}/${myId ? myId : ""}`);
    setResults(results);
    setIsLoading(false);
  };

  useEffect(() => {
    populateResults();
  }, [myId, isByKeyword]);

  useEffect(() => {
    if (pathname.includes("category")) {
      setIsByKeyword(true);
    } else {
      setIsByKeyword(false);
    }
  }, [pathname]);

  return (
    <main className="px-4 mx-auto mt-12 space-y-6 wrapper">
      <section className="flex justify-between">
        <div className="flex flex-col w-full space-y-2 lg:justify-between lg:items-center lg:flex-row lg:space-y-0">
          <h1 className="text-xl font-bold text-white lg:text-3xl">
            Browse the Horror Lex® Database
          </h1>
          <a
            className="block text-xl font-semibold text-primaryRed cursor-pointer"
            onClick={() => {
              if (isCategory) {
                navigate("/browse/film/A");
              } else {
                navigate("/browse/category/A");
              }
            }}
          >
            {`Browse by ${isByKeyword ? "Film" : "Keyword"}`}
          </a>
        </div>
      </section>
      <section className="px-6 py-6 shadow-md rounded-xl bg-blackLight">
        <div>
          {isByKeyword && (
            <h2 className="text-xl font-bold text-white">
              Browse Keywords - Alphabetically
            </h2>
          )}
          {!isByKeyword && (
            <>
              <h2 className="text-xl font-bold text-white">
                Browse Films - Alphabetically
              </h2>
              <p className="mt-2 text-gray-400">
                Films are typically listed first with the title most well-known
                in the U.S., followed by alternative and foreign titles.
              </p>
            </>
          )}
          <div className="mt-4 space-x-2 text-white lg:space-x-4 xl:space-x-6 container flex flex-row flex-wrap">
            {Array.from(Array(27), (e, i) => {
              let charCode = i + 64;
              let char = String.fromCharCode(charCode);
              if (i === 0) {
                char = "num";
              }
              return (
                <Link
                  to={`/browse/${isByKeyword ? "category" : "film"}/${char}`}
                  key={i}
                >
                  <div className="text-lg font-bold text-gray-500 hover:text-primaryOrangeDark">
                    {char === "num" ? "#" : char}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <p className="mt-8 mb-2 text-sm text-gray-600">
          Showing {results.length} entries
        </p>

        <div className="col-span-2 p-6 overflow-y-auto rounded-lg lg:col-span-1 bg-blackMedium">
          <ul className="grid gap-12 lg:grid-cols-3">
            {results.map((each, index) => {
              return (
                <a
                  href={`/results?count=10&page=1&query=${encodeURI(
                    each.title
                  )}&sort=title&type=${path === "category" ? "tag" : "film"}`}
                  className="block"
                  key={index}
                >
                  <li className="text-lg font-medium text-gray-500 cursor-pointer hover:text-primaryOrangeDark">
                    {each.title}
                  </li>
                </a>
              );
            })}
          </ul>
        </div>
      </section>
    </main>
  );
};

export default Browse;
